/*
	jQuery.mmenu position extension CSS
*/
.mm-menu.mm-top,
.mm-menu.mm-bottom {
  width: 100%;
  min-width: 100%;
  max-width: 100%; }

.mm-menu.mm-right {
  left: auto;
  right: 0; }

.mm-menu.mm-bottom {
  top: auto;
  bottom: 0; }

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -moz-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  -o-transform: translate(-80%, 0);
  transform: translate(-80%, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0); } }
@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -moz-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    -o-transform: translate(-440px, 0);
    transform: translate(-440px, 0); } }
/*
	jQuery.mmenu z-position extension CSS
*/
html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  z-index: 0 !important; }

.mm-menu.mm-front {
  z-index: 1; }

.mm-menu.mm-front, .mm-menu.mm-next {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }
  .mm-menu.mm-front.mm-right, .mm-menu.mm-next.mm-right {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
.mm-menu.mm-top {
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }
.mm-menu.mm-bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

html.mm-opening .mm-menu.mm-front, html.mm-opening .mm-menu.mm-next {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.mm-menu.mm-top, .mm-menu.mm-bottom {
  height: 80%;
  min-height: 140px;
  max-height: 880px; }
