/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu oncanvas CSS
*/
.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-panels,
.mm-panels > .mm-panel {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  overflow: hidden;
  padding: 0; }

.mm-panel {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }
  .mm-panel.mm-opened {
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); }
  .mm-panel.mm-subopened {
    -webkit-transform: translate3d(-30%, 0, 0);
    -moz-transform: translate3d(-30%, 0, 0);
    -ms-transform: translate3d(-30%, 0, 0);
    -o-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0); }
  .mm-panel.mm-highest {
    z-index: 1; }

.mm-panels,
.mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit; }

.mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px; }
  .mm-panels > .mm-panel.mm-hasnavbar {
    padding-top: 40px; }
  .mm-panels > .mm-panel:before, .mm-panels > .mm-panel:after {
    content: '';
    display: block;
    height: 20px; }

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important; }

.mm-vertical .mm-listview .mm-panel,
.mm-listview .mm-vertical .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after,
  .mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent; }

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block; }

.mm-vertical .mm-listview > li > .mm-next,
.mm-listview > li.mm-vertical > .mm-next {
  height: 40px;
  bottom: auto; }
  .mm-vertical .mm-listview > li > .mm-next:after,
  .mm-listview > li.mm-vertical > .mm-next:after {
    top: 16px;
    bottom: auto; }
.mm-vertical .mm-listview > li.mm-opened > .mm-next:after,
.mm-listview > li.mm-vertical.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .mm-navbar > * {
    display: block;
    padding: 10px 0; }
  .mm-navbar a,
  .mm-navbar a:hover {
    text-decoration: none; }
  .mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .mm-navbar .mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    z-index: 1; }
    .mm-navbar .mm-btn:first-child {
      padding-left: 20px;
      left: 0; }
    .mm-navbar .mm-btn:last-child {
      text-align: right;
      padding-right: 20px;
      right: 0; }

.mm-panel .mm-navbar {
  display: none; }
.mm-panel.mm-hasnavbar .mm-navbar {
  display: block; }

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px; }
  .mm-listview a,
  .mm-listview a:hover {
    text-decoration: none; }
  .mm-listview > li {
    position: relative; }
    .mm-listview > li, .mm-listview > li:after,
    .mm-listview > li .mm-next,
    .mm-listview > li .mm-next:before {
      border-color: inherit; }
    .mm-listview > li > a,
    .mm-listview > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
      .mm-listview > li > a.mm-arrow,
      .mm-listview > li > span.mm-arrow {
        padding-right: 50px; }
    .mm-listview > li:not(.mm-divider):after {
      content: '';
      border-bottom-width: 1px;
      border-bottom-style: solid;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    .mm-listview > li:not(.mm-divider):after {
      left: 20px; }
  .mm-listview .mm-next {
    background: rgba(3, 2, 1, 0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2; }
    .mm-listview .mm-next:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; }
    .mm-listview .mm-next + a,
    .mm-listview .mm-next + span {
      margin-right: 50px; }
    .mm-listview .mm-next.mm-fullsubopen {
      width: 100%; }
      .mm-listview .mm-next.mm-fullsubopen:before {
        border-left: none; }
      .mm-listview .mm-next.mm-fullsubopen + a,
      .mm-listview .mm-next.mm-fullsubopen + span {
        padding-right: 50px;
        margin-right: 0; }

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px; }
.mm-panels > .mm-panel > .mm-listview:first-child,
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px; }

.mm-listview .mm-inset {
  list-style: inside disc;
  padding: 0 10px 15px 40px;
  margin: 0; }
  .mm-listview .mm-inset > li {
    padding: 5px 0; }

.mm-listview .mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px; }

.mm-listview .mm-spacer {
  padding-top: 40px; }
  .mm-listview .mm-spacer > .mm-next {
    top: 40px; }
  .mm-listview .mm-spacer.mm-divider {
    padding-top: 25px; }

.mm-prev:before,
.mm-next:after,
.mm-arrow:after {
  content: '';
  border: 2px solid transparent;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 23px; }

.mm-next:after,
.mm-arrow:after {
  border-top: none;
  border-left: none;
  right: 23px; }

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7); }
  .mm-menu .mm-navbar > *,
  .mm-menu .mm-navbar a {
    color: rgba(0, 0, 0, 0.3); }
  .mm-menu .mm-navbar .mm-btn:before, .mm-menu .mm-navbar .mm-btn:after {
    border-color: rgba(0, 0, 0, 0.3); }
  .mm-menu .mm-listview {
    border-color: rgba(0, 0, 0, 0.1); }
    .mm-menu .mm-listview > li .mm-next:after,
    .mm-menu .mm-listview > li .mm-arrow:after {
      border-color: rgba(0, 0, 0, 0.3); }
    .mm-menu .mm-listview > li a:not(.mm-next) {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
      tap-highlight-color: rgba(255, 255, 255, 0.5); }
    .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
    .mm-menu .mm-listview > li.mm-selected > span {
      background: rgba(255, 255, 255, 0.5); }
  .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next,
  .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
  .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
  .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel {
    background: rgba(0, 0, 0, 0.05); }
  .mm-menu .mm-divider {
    background: rgba(0, 0, 0, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-opened {
  overflow-x: hidden;
  position: relative; }

html.mm-blocking {
  overflow: hidden; }
  html.mm-blocking body {
    overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }
.mm-menu.mm-current {
  display: block; }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -moz-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  -o-transform: translate(80%, 0);
  transform: translate(80%, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -moz-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    -o-transform: translate(140px, 0);
    transform: translate(140px, 0); } }
@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -moz-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    -o-transform: translate(440px, 0);
    transform: translate(440px, 0); } }
