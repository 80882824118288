// VARIABLES
$blue : #1a3f78; 
$green : #79b637;
$grey : #666;


body { min-width: 320px; font-family: 'Proxima-Nova', helvetica, sans-serfif;}
ol,ul,li { list-style: none;}
img { max-width: 100%;}
.row {max-width: 1100px;}
.img_border { 
	border: 2px solid #fff; 
	-webkit-box-shadow: 0 0 8px -1px #999999;
	-moz-box-shadow: 0 0 8px -1px #999999;
	box-shadow: 0 0 8px -1px #999999;
}

// FONT STYLES
h1 { color: $blue; font-size: 1.6em; margin-bottom: 0.6em; font-weight: 100; text-align: center; }
h2 { color: $blue; font-size: 1.6em; margin-bottom: 0em; font-weight: 100; text-align: center; }
h3 { color: $blue; font-size: 1.3em; font-weight: 400; }
h4 { color: $blue; font-size: 1.4em; font-weight: 400; }
h5{ color: #fff; font-size: 1.1em;}
h6{ color: #fff; font-size: .9em; font-weight: 100; text-transform: uppercase;}

article h1 { color: $green; font-size: 1.6em; margin-bottom: 0.6em!important; font-weight: 600!important; text-align: center; }
article h2 { color: $blue; font-size: 1.3em; margin-bottom: 0.3em; margin-top: 0.4em; font-weight: 600; text-align: left; }
article h3 { color: $blue; font-size: 1.2em; margin-bottom: 0.6em; font-weight: 600; text-align: left; }
article h4 { color: $blue; font-size: 0.9em; font-weight: 400; }
article h5{ color: #fff; font-size: 1.1em;}
article h6{ color: #fff; font-size: .9em; font-weight: 100; text-transform: uppercase;}
article ul {margin-left: 2em!important;}
article li {padding: 0.2em 0;}
article p {margin-bottom: 1.4em;}

p, li, table, label { font-size: .85em; color: $blue; }
td{ padding: 3px; }
p {line-height: 2em;}

a{ color: $green; transition: all ease 1s;}
a:hover { color: $blue;}

blockquote{
	color: $green; 
	font-size: 1em;
	border-left: 2px solid $green;
	font-weight: 100;
	font-style: italic;
	margin-left: 1em;
}

// HEADER STYLES
/*#home header{ padding-top: 1em;}*/
header {
	min-height: 200px;	

	a img:hover{opacity: 0.6;}

}

.mm-page {overflow: hidden;}
.main_nav{ display: none;}

.double-dropdown {width: 600px!important; background: #79b637;}
.double-dropdown li {width: 50%!important; float: left;} 
.f-dropdown a {padding: 0em .4em!important;}

	
.f-dropdown{
	width: 320px;
	margin-top: -9px!important;
	position: absolute;
	z-index: 999999!important;
	margin-left: -12px!important;
	max-width: none;
	padding: 0.7em 1em 1em 1em!important;
	background-color: $green;

	li{
		width: 295px;
		text-align: left;
		background-color: $green;
		position: relative;
		z-index: 9999!important;
		padding: 0 0.5em;
		margin: 0px;
		float: left;
		

		a{
			transition: all ease 0.5s;
			border-bottom: 1px solid #EBFFD5;
			margin: 0px!important;
			padding: 0.6em 1em!important;
			float:left;
			width: 100%;
			font-size: .8em!important;
		}
		
		a:hover{
			background-color: $blue!important;
		}
	}


}
.mm-menu{
	background-color: $green;

	.mm-listview > li > a{
		color: #fff;
	}
	.mm-listview > li:hover{
		background-color: $blue;
	}

	a.mm-title{
		color: #fff;
	}
	.mm-listview > li .mm-next:after, .mm-listview > li .mm-arrow:after{
		border-color: #fff;
	}
	.mm-navbar .mm-btn:before, .mm-navbar .mm-btn:after {
		border-color: #fff;
	}
}

.reveal-modal p {font-weight: bold;}

.top-bar{ 
	padding: .5em 0;
	.logo{ text-align: center;	}
	
	.contact {
		padding-top: 2em;

		p{
			text-align: right;
			color: $blue;
			font-weight: 400;
			font-size: .95em;

			a{
				color: $blue;
				margin-left: 2em!important;
			}
			a:hover{
				color: $green;
			}
		}
		.p-green{
			color: $green;
			font-size: 1em;
			font-weight: 700
		}
	}
}


.overlay{
	text-align: center;
	margin-top: 0em;
	padding: 3em 0 0em;

		p{color: #fff;}

		hr{ 
			margin: 5px 0;
			border: solid #fff;
	    	border-width: 2px 0 0;
	    }
		
		p.level1h{
			font-size: 1.2em;
			font-weight: 100;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		p.level2h{
			font-size: 1.8em;
			margin-bottom: 0;
			font-weight: 600;
			text-transform: uppercase;
			line-height: 1.2em;
		}
		p.level3h{
			font-size: 1em;
			margin-top: 1em;
			font-weight: 400;
		}

		.services-btn{
			background-color: $green;
			padding: 10px 15px;
			color: #fff;
			font-size: 1em;
			text-transform: uppercase;
			font-weight: 400;
		}
		.services-btn:hover{
			background-color: $grey;
		}
}



.mobile_menu {
	text-align: right;
	padding-top: .5em;

		a{
			font-size: 1.4em;
			text-transform: uppercase;
			color: #959595;
			font-weight: 600;

		}
		
		img { 
			max-width: 28px;
			max-height: 28px;
			margin-left: .3em;
		}
}


.slick-prev::before, .slick-next::before {color: #011b30; font-size: 25px;}

section {
	padding: 1em 0;
}


#physio-treatments {
	text-align: center;
	li{
		padding: 1em;
	}

	li:hover, .service-container:hover {
		img, .treatment-btn{ background-color: $green; }
	}
   
    a {color: #fff;}

	p{ margin-bottom: .6em; color: $grey;}
	

	img{
		background-color: $blue;
		border-radius: 50%;
		margin-bottom: 1em;
		max-width: 140px;
	}
	img:hover{
		background-color: $green;
	}
	
	.treatment-btn{
		background-color: $blue;
		padding: 6px 15px;
		color: #fff;
		text-transform: uppercase;
		width: 60%;
    	margin: 1em auto;
	}
	.treatment-btn:hover{
		background-color: $green;
	}

	.green-hover:hover {
	
		img, .treatment-btn{ 
			background-color: $green!important;
			
		}
	}
	.green-hover-active {
	
		img, .treatment-btn{ 
			background-color: $green!important;
			
		}
	}

}

.slick-dots li {position: relative; right: 30px;}


#physio-treatments img{ max-width: 100%; height: 190px; width: 190px; margin: 0 auto; margin-bottom: 1em;}

#physio-treatments h2 {margin-bottom: 0.8em;}
#physio-treatments h3 {font-size: 1.3em; text-align: center!important;}

#physio-treatments .intro h1 {margin-bottom: 0em;}
#physio-treatments .intro h3 {margin-bottom: 2em;}

.margin-top{ margin-top: 1em;}

// BUTTONS
.green-btn{
	background-color: $green;
	padding: 10px 30px;
	color: #fff;
	font-size: 1em;
	text-transform: uppercase;
	font-weight: 400;
	
}

.green-btn:hover{ 
	background-color: $grey;
	color: #fff;
	transition: all ease 0.4s;
}

.blue-btn{
		background-color: $blue;
		padding: 7px 25px;
		text-transform: uppercase;
		width: 70%;
    	margin: 0 auto;
    	color: #fff;
    	a{ color: #fff; font-size: .95em;}
}

.blue-btn:hover{ background-color: $green; color: #fff; transition: all ease 0.4s;}

.test-container{
	margin-top: 3em;
	.testimonials{
		background-color: $grey;
		background-color: rgba(255,255,255, 0.2);
		padding: 8px 25px;
		text-transform: uppercase;
		width: 60%;
    	margin: 1em auto 0;
    	color: #fff;
    	
    }
    .testimonials:hover{ background-color: $green;}
}

.green-testimonial {background-color:#b7d694!important; }
.green-testimonial p {color: #193d71!important;}

.testimonials-container {margin-top: 3em;}

.banner{
	min-height: 400px; 
	max-width: 1500px; 
	margin: 1em auto;

	blockquote{
		color: #fff; 
		font-size: 2em;
		line-height: 1.2em;
		border: none;
		font-weight: 100;
		margin: 1.4em 0 0 0;
		font-style: normal;
	}
	
	blockquote p {
		
		line-height: 1.25em;
		
		}
	

	.quote-block{
		background: url(../images/quote-marks.png) center top no-repeat;
		background-position: fixed;
		background-color: rgba(1,27,48,0.9);
		margin-top: 3em;
		padding: 1.6em 2em 1em;
		
		li {color: #ffffff; font-size: 1em;   font-weight: 700; margin-left: 30px;}
		p {color: #ffffff; font-size: 1.1em; margin-bottom: 1em; margin-left: 20px; line-height: 1.6em;}
	}

	p{
		color: #fff;
		font-size: 0.8em;
		font-weight: 100;
		margin-bottom: 0;
	}
	h4{
		color: #fff;
		font-size: 0.65em;
		font-weight: 500;
		margin-bottom: 0;
	}
	.author{
		text-align: right;
		font-size: .9em;
	}
}

.news_feed { 
	text-align: center;

	p{ color: $grey;}

	h4{
		margin-top: 1em;
	}
	li{
		margin: .5em 0;
		padding: 1em;
	}
}


.featured-news img {width: 100%;}
.featured-news .overlay {width: 100%;}

// HOMEPAGE GENERIC STYLES
.news-home h2 { margin-bottom: 0em;}

.grey-back{
	margin-top: 1em;
	background: #eaeef3;
	text-align: left;
	h4{ color: #666666; font-size: 1em; font-weight: 300;}
	h2{
		text-align: left;
		text-transform: uppercase;
		font-size: 1.6em;
		margin: .5em 0;

	}

	iframe{
		min-height: 200px;
	}
}


// INNER PAGE GENERIC STYLES
.team-back-inner h1, .news-back-inner h1{ color: $blue; font-size: 1.4em; text-align: left; font-weight: 400; margin-bottom: 0em;}
.small-banner {float: left; width: 100%; height: 250px; min-height: 250px; margin-bottom: 1.5em;}


// TEAM PAGE
.team{max-height: 200px;}
.team-back{ 
	background-color: #f5f7f1; 
	text-align: center;
	padding: 2em;
	margin-top: -8em;

	h4 {font-size: 1.2em;}
	p {font-size: 1em;}
	ul li{
	    padding: 0 1em;
		h3{ font-size: 1.6em;}
	}
	
	img{ margin-bottom: 1em; }
}



// TEAM INNER PAGE
.team-back-inner{ 
	background-color: #f5f7f1; 
	padding: 2em 1em;
	margin-top: -8em;

	p{
		color: $blue;
	}

	ul li{ font-size: 1em;
		list-style: disc;}

}

.mob-profile img{ margin-bottom: 1em;}

.team-banner{ 
	min-height: 400px;
	h2 a{ color: #fff; }
	.quote-block{
		background-color: rgba( 0, 0, 0, 0.6);
		padding: 1.5em 1em;
		margin-top: 3em;
	}
}

.side_navigation { 
	min-height: 230px;
	margin-top: 1em;
	background-color: #f5f7f1;
	padding: 2.3em 0;

	ul { margin: 0; }
	li { padding: .5em 0 .5em 2em; color: #fff;}
	a li { color: #123e7a; font-size: .9em; }
	li.active { 
		background-color: $blue; 
		color: #fff;
	}

	li:hover{ 
		background-color: $blue; 
		color: #fff;
	}
	h3{ 
		padding-left: 1em; 
		font-size: 1.3em;
		font-weight: 600;
	}

}

.team-m{ background-color: #f5f7f1;	padding: 1em 2em; margin-top: 1em; color: $green;}
.team-m:hover{ background-color: $grey; color: #fff;}
.enquiry{ background-color: $green; padding: 1em; margin-top: 1em; color: #fff; text-transform: uppercase;}
.enquiry:hover{ background-color: $blue;}

.join{ 
	margin: 1em 0;
		a{color: $blue;
		font-weight: 600;}
		a:hover{color: $green;}
	img{
		padding-right: 10px;
	}
}

.qualifications{ font-size: .95em; color: $grey; }


// NEWS PAGE
.news-banner{min-height: 200px;}
.news-back-inner{ 
	background-color: #f5f7f1; 
	padding: 1.8em 1em;
	margin-top: -8em;
	h1 {margin-bottom: 0.5em;}
	p{
		color: $blue;
	}
	ul li  {
		list-style: disc;
		color: $blue;
	}
}

// CASESTUDY PAGE
.case-back{ 
	background-color: #f5f7f1; 
	text-align: left;
	padding: 1em;
	margin-top: -10em;

	.blue-btn{ text-align: center;}
	.blue-border{ border: 1px solid $blue; padding: .8em;}

	ul li{ font-size: 1em;
		list-style: disc;}

}

.testimonial{ 
	background: url(../images/case-quote.png) left top no-repeat;
	background-size: 316px 63px;
    background-position: fixed;
    background-color: rgba(25, 61, 113, 1);
	padding: 1em 1.5em;
	margin-bottom: 2em;
	p {color: #fff;}

	.author{ text-align: right;}
}


.casestudy-enquiry{ 
	min-height: 400px;
	margin: 2em 0;
	h2 a{ color: #fff; }
	.quote-block{
		background-color: rgba(1, 27, 48, 0.9);
		padding: 1.5em 1em;
	}
}
li.tip {list-style-type: decimal; color: $green; font-size: 1.5em; padding-right: 10px;}
li.tip p {font-size: .65em;}
.tip::before {font-size: 1em;}

/**
* Masonry
*/
.grid-item { width: 90%; margin: 0 1% 5%; }
.grid-item--width2 { width: 66.666%; }


// PRICES PAGE
.prices-banner { max-width: 1700px; margin: 0 auto;}
.side_info { 
	background-color: $blue; 
	padding: 1em;
	color: #fff;
	margin-top: 2em;

	p{ color: #fff; font-size: 1.2em; }

	ul li{ list-style: disc; color: #fff;}
}
table{ color: $blue; width: 100%;}
tr.bborder{ border-bottom: 1px solid #dadada; }



td.therapy-type, td.therapy-type-space, td.therapy-type-space2 { font-weight: 600; width: 50%; padding: 15px 0; font-size: 1.2em;}
tr.bborder2 { 
	border-bottom: 1px solid #dadada;
	
}
td.therapy-type-space, .therapy-space {padding: 20px 0 0 0;} 
td.therapy-type-space2{ padding: 0px; }

// SERVICE PAGE
.treatment_slider img{ margin: 0 20%;}
.gallery_slider{ margin: 0 10%;}

.service-container {margin-bottom: 2.25em; padding: 0 0.6em;}


// CONTACT PAGE
.map iframe { max-height: 200px;}
#contact .side_navigation { margin-top: 1em;}
#contact .side_info { 
	margin-top: 0;
	p{ text-transform: uppercase;}
	
	ul{
		margin: 0;
	
	li{ list-style: none;}

	}
}

#contact form {padding-top: 1em;}
#contact .contact-info{
	background-color: #f5f7f1;
    background: url(../images/w-icon.png) right bottom no-repeat #f5f7f1;
	margin-top: 0em;
	padding: 1em;
}
#contact .green-box{
	background-color: $green;
	width: 100%;
	float: left;
    margin: 2% 0;
    text-align: center;
    padding: .5em 0;

    h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 0em;
	}
	h3{
    color: #fff;
    font-size: 1em;
    a{color: #fff;}
    a:hover{color: $blue;}
	}
}




#contact .blue-box{
	background-color: $blue;	
	margin: .5em 0;
	padding: .5em 2em;

	h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-bottom: 0em;
	}

	h3{
    color: #fff;
    font-size: 1em;
	}
}

#contact .large-green{
	background-color: $green;
	padding: 1em 0;
}

#contact .green-tab{
	background-color: $green;
	color: #ffffff;
	float: left;
    margin: 0 1%;
    padding: 10px 10px 0px;
    width: 100%;

}

#contact form ul li {margin: 0px;}

#contact textarea {float: left; margin-top: 0.2em;}

#contact input[type="checkbox"] {margin-right: 10px;}

// Form 
form {
	padding: 3em 0 0;
}

.heading {
		padding: 0!important;
		h4{
			padding: .5em 0;
		}
	}


.honey_container { display: none;}
form input[type=text], form select { height: 45px;}
form input[type=text], form textarea, form select { 
	font-size: 1em;
	color: $blue;
}
form ::placeholder, form select { color: #000;}
form input[type=submit] { 
	background: $blue;
	color: #fff;
	padding: .6em 2em;
	transition: background .5s ease;
	border: none;
	text-transform: uppercase;
	width: 100%;
	letter-spacing: 3px;
}
form input[type=submit]:hover { 
	background: $green;
	color: #fff;
	transition: background .5s ease;
}

/**
* Footer
*/
footer { 
	background: #011b30; 
	padding: 0em 0 1em; 
	color: #fff;
	text-align: center;

	p, li, a {
		color: #fff;
		margin-bottom: .6em;
	}
	
	a:hover{
		color: $green;
	}

	.top-bar{ 
		background-color: #082D4C; 
		padding: 0.3em 0;
		h5{
			margin: 0;
			padding: 5px 0;
			font-size: 1em;
		}
	}

	.bottom-bar{
		background-color: #03101b;
		text-transform: uppercase;
		font-size: .85em!important;
		font-weight: 100;
			
			p{
				margin: .6em 0;
				color: #ABABAB;
	
				a{
				    color: #ABABAB;
				}
				a:hover{
					color: $green;
				}
			}
	}

	.newsletter { 
		margin-top: .85em;
		p{
			font-size: .85em;
		}
	}

	a img:hover{opacity: 0.7;}

	.capitals { text-transform: uppercase; }

	li ul{ margin-left: 0em;}

	li h6{
		font-size: .9em;
		font-weight: 100;
		text-transform: uppercase;
	}

	.links { 
		padding: 1em;
		li, a{ font-size: .9em;}	
	}
	.social {
		text-align: center; 
	}
	.twist { 
			text-align: center; 
			padding: 1em 0;
		}
	.twist-img{ max-width: 130px; }
	.phone-number{ font-size: 1.2em; }
	h5 a { color: #fff; }
	h6{ color:#ABABAB; font-size: 1em;}
}

footer .button {padding: 8px 16px!important; background: #79b637;}


tr {
   line-height: 26px;
}

.side_navigation li {
   padding: 0.5em 1em .5em 2em;
}

#contact .side_info ul li {
   line-height: 30px;
}




footer .newsletter p{
font-size: .8em;
}

footer .links li{
font-size: .8em;
}

.blue-btn {
   padding: 6px 25px;
font-size: .9em;
}


.accordion .accordion-navigation > .content.active, .accordion dd > .content.active {background-color: transparent;}
.accordion .accordion-navigation > a, .accordion dd > a {color:#1a3f78; font-size: 1.1em; padding: 0.8em 1em;}
.accordion .accordion-navigation.active > a, .accordion dd.active > a {color:#1a3f78; font-size: 1.1em;  padding: 0.8em 1em;}

.cta{ 
	background-color: $blue; 
	padding: 1em .5em; 
	margin-bottom: 1em;
	text-align: center;
	h2, h3, a{ color: #fff; }
	h2{ font-size: 1.8em; }
	h3{ font-size: 1.1em; margin: 0;}
	p{ margin-top: 1em; }
	p a{
		background-color: #79b637;
		padding: 10px 5em;
		text-transform: uppercase;
	}
	p a:hover{
		background-color: $grey;
	}

	h3 a:hover{ color: $green; }
}
.n-pad{ padding: 0; }


.contact-info .button {background: $green; color: #ffffff; padding-left: 1.45em; padding-right: 1.5em; }
.contact-info .button:hover {background: $blue;}
.contact-info .button li {color: #ffffff; font-size: 0.95em; }

#contact .blue-btn {margin: 1em 0; width: 100%;  padding: 0.75em 2em;}
.prices-container .blue-btn {margin: 1em 0; width: 100%!important;  padding: 0.75em 2em;}


@media #{$small-only}{
	
section {padding-left: 0.75em; padding-right: 0.75em;}
.news-back-inner {margin-top: 0px;}
	
.team-back ul li{
		width: 100%;
		margin-bottom: 1em;
	}

	.blue-btn {
	font-size: .9em;
	}

	table{ color: $blue; width: 100%; font-size: .8em;}

	footer .top-bar{ margin-bottom: 1em;}
	.slick-dots {display: none!important;}
	
	.team-back, team-back {margin-top: 0em;}
	.grid-item {width: 97%;}
	
	
	footer .privacylink {display: block;}
	
}


@media #{$medium-only}{
footer .links{ padding: 0 1em!important;}
.links li{ padding: 0; margin: .5em 0!important; }
table {font-size: 0.85em;}


}

@media #{$medium-up}{
h1 { font-size: 2.6em; }
h2 { font-size: 2.8em;}
h3 { font-size: 1.75em; }
h4 { font-size: 1.4em; }

.blue-btn {
	font-size: .9em;
}

.team-back ul li {
   margin-bottom: 2em;
}

blockquote{
	font-size: 1.2em;
	margin-left: 2em;
}

.service-container article {min-height: 370px;}

// INNER PAGE GENERIC STYLES
.team-back-inner h1, .news-back-inner h1{ font-size: 1.8em; }
.side_navigation h3 {
    font-size: 1.4em;
}
[class*="block-grid-"] > li{margin: 1em 0;}
.grid-item { width: 45%; margin: 0 2.5% 5%; }
.grid-item--width2 { width: 66.666%; }
.testimonial{ 
	padding: 3em 3em;
}

section {
	padding: 1em 0;
}

header {
	min-height: 380px;	 
	}

.top-bar{ 
	padding: 0;

	.logo{ 
		margin-top: -2em; 
	}
	
	.contact {
		padding-top: 0em;

			p{
				text-align: right;
				font-size: .95em;
			}

			.p-green{
				color: $green;
				font-size: 1em;
			}
		}
	}

.telephone {font-size: 1.62em!important;}

.overlay{
	margin-top: 0em;
		
		p.level1h{
			font-size: 1.6em;

		}

		p.level2h{
			font-size: 2.6em;

		}
		p.level3h{
			font-size: 1.1em;

		}

		.services-btn{
			font-size: 1.3em;
		}
	}


	.green-btn{
		margin: 3em;

	}


.grey-back{
	h4{ font-size: 1.2em; }
	h2{
		font-size: 2em;
	}
	iframe{
		min-height: 280px;
	}
}

// TEAM INNER PAGE
.team-banner h2{ font-size: 2.6em;}
.team-inner{min-height: 350px;}

.team-back-inner, .side_navigation, .news-back-inner, .case-back, .team-back { 
	margin-top: -8em; 
	
}

// SERVICES
.gallery{ padding: .5em 0;}

.cta{ 
	padding: 2em 1em; 
	margin-bottom: 2em;
	h2{ font-size: 2.6em; }
	h3{ font-size: 1.4em; margin: 0;}
	p{ margin-top: 1.5em; }
	p a{
		padding: 10px 5em;
		margin: 1em;
	}
}

// News
.news-banner{min-height: 400px;}
.news-back-inner{ padding: 2em 1em; 
	ul li{
		
		list-style: disc;
	}
}
.enquiry{ padding: 1em;}
.side_info { 
	margin-top: -8em;
	

	p{
		font-size: 1.2em;

	}
	li{color: #fff;}
}

// CASESTUDY PAGE
.case-back{ 
	padding: 2em;
	.blue-border{padding: 1.5em;}

}
.blue-btn{
	width: 60%;
}

// CONTACT PAGE
.map iframe{ max-height: 400px;}

// Footer
footer { 
		padding: 0 0 2em 0; 
		color: #fff;
		text-align: left;

		p, li, li a{
			margin-bottom: .6em;
		}

		li h6{
			font-size: 1.2em;
			font-weight: 100;
			text-transform: uppercase;
		}

		.links { padding: 1em 0;
			li {
				font-size: 0.78em;	
			}
			a{
				font-size: 1em;	}
				.cd p{ font-size: 1em; }
		}
		.social, .twist { text-align: right; }
		
		.bottom-bar p{
			    margin: 1.8em 0 0;
		}

	}

	#contact .contact-info{
	margin-top: -8em;
	padding: 2em;
	}
	#contact .side_info{
		margin-top: -8em;
	}

}

@media #{$large-up}{
	

	#home header{ padding: 0em 0 1em; }
	
	header{	min-height: 400px;}

		.main_nav{ 
			background-color: #123e7a;
			display: block;
			text-align: center!important;


			ul{
				margin: 0;
				padding: 0;
				text-align: center;

				li{
					margin-right: 0em;
					display: inline-block;
					padding: 9px .55%;

						
						a, .click_one a {
							color: #fff;
							padding: 20px 0;
							text-transform: uppercase;
							font-size: 0.92em;

						}
						
						ul li {	
							padding: 0px .98%;
							}
				}

				li:hover { background-color: $green; }
			}
		}


	.f-dropdown {z-index: 9999!important;}

	.grey-back{
		h4{ font-size: 1.4em; }
		h2{
			font-size: 2.6em;
		}
	}
	

	#home .main_nav{ 
			background-color: #123e7a;
			background-color: rgba(10,33,67,1);
		}
	#physio-treatments img{ max-width: 100%; height: 190px; width: 190px;}
	#physio-treatments article {min-height: 330px;}

	.banner blockquote{	
			font-size: 2.8em;
			line-height: 1.22em;
	}


	.top-bar{
		.contact { padding-top: 2em;
			p{ font-size: 1.2em; }
			.p-green{ font-size: 1.2em;	}
		 }

		.logo{margin: 0.5em 0em 0.5em; padding: 0em;}

		
	}

	.overlay{
		margin-top: 0em;
		
		p.level1h{
			font-size: 2.2em;

		}

		p.level2h{
			font-size: 4em;

		}
		p.level3h{
			font-size: 1.2em;

		}

		.services-btn{
			font-size: 1.3em;
		}
	}

.enquiry{ padding: 1em 2em;}



// Team page
.team-inner{min-height: 400px;}

// RATES
.side_info {  
	padding: 2em;
}

// News page
.news-back-inner{ padding: 2em 3em;}
.casestudy-inner{min-height: 400px;}	

.grid-item { width: 31.333%; margin: 0 1% 2%; }
.grid-item--width2 { width: 66.666%; }

#contact .green-box{
	width: 49%;
	float: left;

}

#contact .box-2{
    margin-left: 2%;
}

#contact .green-box {min-height: 95px;}

}



